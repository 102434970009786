import { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import Models from './scripts/3DModels';
import CanvasResize from './scripts/CanvasResize';

function App() {
  useEffect(() => {
    CanvasResize();
    Models();

    const lBrace = document.getElementById('l-brace');
    const rBrace = document.getElementById('r-brace');

    const handleMouseMove = (e) => {
      const lBraceRect = lBrace.getBoundingClientRect();
      const rBraceRect = rBrace.getBoundingClientRect();

      const lDistanceX = Math.abs(e.clientX - (lBraceRect.left + lBraceRect.width / 2));
      const lDistanceY = Math.abs(e.clientY - (lBraceRect.top + lBraceRect.height / 2));
      const rDistanceX = Math.abs(e.clientX - (rBraceRect.left + rBraceRect.width / 2));
      const rDistanceY = Math.abs(e.clientY - (rBraceRect.top + rBraceRect.height / 2));

      const maxDistance = (window.innerWidth + window.innerHeight)/2.5;
      const lDistance = Math.sqrt(lDistanceX * lDistanceX + lDistanceY * lDistanceY);
      const rDistance = Math.sqrt(rDistanceX * rDistanceX + rDistanceY * rDistanceY);

      const lOpacity = Math.max(0.1, Math.min(0.5, 1 - lDistance / maxDistance));
      const rOpacity = Math.max(0.1, Math.min(0.5, 1 - rDistance / maxDistance));

      lBrace.style.opacity = lOpacity;
      rBrace.style.opacity = rOpacity;
    };

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div className="App">
      <canvas id="bg"></canvas>
      <div id='canvas-placeholder'>
        <pre id='l-brace'>{"{"}</pre>
        <pre id='r-brace'>{"}"}</pre>
      </div>

      <p id='author-title'>xsiadron.com</p>
    </div>
  );
}

export default App;
